import { Form, Modal, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { ICArroba, ICCheckV2 } from "../../../icons";
import { HRInput } from "../../../components/form/input";
import { HRForm } from "../../../components/form/form";
import { HRButton } from "../../../components/button";
import { useReceivingLink } from ".";
import { useTesting } from "../hooks/useTesting";
import { EMAIL_REGEX } from "../../../utils/constants";
import { EmailConfirmation } from "./email_confirmation";
import { useCandidateLayoutContext } from "../../../layout/candidate";
import i18next from "../../../i18n";

export const GeneralAssessment: React.FC = () => {
    const [form] = useForm();
    const { companyProfile } = useCandidateLayoutContext();
    const { inviteWithoutLoginCandidate } = useTesting();
    const { assessmentDetails } = useReceivingLink();
    const [isModalVisible, setIsModalVisible] = React.useState(false);

    const childForm = (
        <div className="grid gap-[16px] grid-cols-[1fr]">
            <Form.Item
                name="email"
                rules={[
                    {
                        pattern: EMAIL_REGEX,
                        message: i18next.t("testing.invalidEmail") ?? ""
                    },
                    {
                        required: true,
                        message: i18next.t("testing.emptyEmail") ?? ""
                    }
                ]}
            >
                <HRInput
                    label="Email"
                    prefix={<ICArroba />}
                    placeholder={i18next.t("testing.enterEmail") ?? ""}
                    isRequired={true}
                    maxLength={50}
                />
            </Form.Item>

            <Form.Item name="lastName">
                <HRInput
                    label={i18next.t("testing.lastname") ?? ""}
                    placeholder={i18next.t("testing.enterLastname") ?? ""}
                    maxLength={50}
                />
            </Form.Item>

            <Form.Item
                name="firstName"
                rules={[
                    {
                        required: true,
                        message: i18next.t("testing.emptyFirstname") ?? ""
                    }
                ]}
            >
                <HRInput
                    label={i18next.t("testing.firstname") ?? ""}
                    placeholder={i18next.t("testing.enterFirstname") ?? ""}
                    isRequired={true}
                    maxLength={50}
                />
            </Form.Item>
        </div>
    );

    const onSubmit = React.useCallback(() => {
        form.validateFields()
            .then((results: any) => {
                assessmentDetails.id &&
                    inviteWithoutLoginCandidate(assessmentDetails.id, results)
                        .then((res) => {
                            if (res?.data) {
                                setIsModalVisible(true);
                            }
                            form.resetFields();
                        })
                        .catch((err) => console.warn(err));
            })
            .catch((err) => console.warn(err));
    }, [form, setIsModalVisible, inviteWithoutLoginCandidate, assessmentDetails]);

    return (
        <div className="flex bg-white max-w-[466px] rounded-[12px] px-[32px] py-[54px] shadow-e-03 box-border overflow-hidden">
            <div className="grid grid-cols-1 gap-[32px] items-start justify-start">
                <div className="grid grid-cols-1 gap-[8px]">
                    <Typography.Title
                        level={5}
                        style={{
                            color: "#110C22",
                            fontWeight: 700,
                            marginBottom: 0,
                            fontSize: "18px"
                        }}
                    >
                        {i18next.t("testing.receiveAssessment")}
                    </Typography.Title>
                    <span className="text-standard text-high-em">{i18next.t("testing.enterInfoBelow")}</span>
                </div>
                <div className="grid grid-cols-1">
                    <HRForm form={form} name="receingLink" childNode={childForm} />
                </div>
                <div>
                    <HRButton
                        onClick={onSubmit}
                        btnType="primary"
                        btnSize="md"
                        style={{
                            backgroundColor: companyProfile?.color?.backgroundColor,
                            borderColor: companyProfile?.color?.backgroundColor,
                            color: companyProfile?.color?.fontColor || "#fff"
                        }}
                    >
                        <div className="flex space-x-2 items-center justify-center">
                            <ICCheckV2 width={24} height={24} fill={companyProfile?.color?.fontColor || "#fff"} />
                            <span>{i18next.t("testing.confirm")}</span>
                        </div>
                    </HRButton>
                </div>
            </div>
            <Modal
                centered
                open={isModalVisible}
                closable={false}
                title={null}
                footer={null}
                style={{
                    borderRadius: "12px",
                    boxShadow: "0px 4px 16px rgba(98, 112, 140, 0.24)"
                }}
                bodyStyle={{
                    padding: "0px"
                }}
            >
                <EmailConfirmation setIsModalVisible={setIsModalVisible} companyProfile={companyProfile} />
            </Modal>
        </div>
    );
};
